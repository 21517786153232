import React from 'react';

import { css } from '@emotion/css';
import { useBooleanFlagValue } from '@openfeature/react-sdk';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { Segments } from '@/components/Configuration/Segments';
import { Exemptions } from '@/components/Exemptions';

const getStyles = (theme: GrafanaTheme2) => ({
  container: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingTop: theme.spacing(1),
  }),
});

export const Configuration = () => {
  const styles = useStyles2(getStyles);
  const segmentationUiEnabled = useBooleanFlagValue('segmentation_ui', false);
  return (
    <div className={styles.container}>
      <Exemptions />
      {segmentationUiEnabled && <Segments />}
    </div>
  );
};

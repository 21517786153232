import React from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { Field, Icon, Select, Tooltip, useStyles2, useTheme2 } from '@grafana/ui';

import { useSegmentContext, useSegments } from '@/hooks';
import { DEFAULT_SEGMENT_OPTION } from '@/util/constants';

const getStyles = (theme: GrafanaTheme2) => ({
  content: css({
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(1),
  }),
  fieldWidth: css({
    marginBottom: 0,
    minWidth: theme.spacing(35),
  }),
});

export const SegmentSelector = () => {
  const styles = useStyles2(getStyles);
  const theme = useTheme2();
  const { data: segments, isError } = useSegments();
  const { segmentId, setSegmentId } = useSegmentContext();

  return (
    <Field className={styles.fieldWidth} label={'Segment'}>
      <span className={styles.content}>
        <Select
          aria-label={'Select segment'}
          onChange={(value) => {
            if (value.value) {
              setSegmentId(value.value);
            }
          }}
          options={segments?.selectableValues || [DEFAULT_SEGMENT_OPTION]}
          value={segmentId}
        />
        {isError && (
          <Tooltip
            content={'Error loading segments. Falling back to default segment. Refresh the page or try again later.'}
          >
            <Icon name={'exclamation-triangle'} color={theme.colors.warning.main} />
          </Tooltip>
        )}
      </span>
    </Field>
  );
};

import { formatISO, subHours } from 'date-fns';
import {
  camelCase as _camelCase,
  isEqual as _isEqual,
  kebabCase as _kebabCase,
  pick as _pick,
  trim as _trim,
} from 'lodash';
import { Row } from 'react-table';

import { dateTime, SelectableValue, TimeRange } from '@grafana/data';

import { getWithHeaders } from '@/api';
import { AggregationRecommendation, AggregationRule, Exemption, hasRuleProp, Recommendation } from '@/api/types';
import { PageDefinition, PageType, RuleRow } from '@/types';
import { errorAlert } from '@/util/alert';
import { paths } from '@/util/constants';

export const getRowId = <T extends object>(
  originalRow: T,
  relativeIndex: number,
  parent?: Row<T> | undefined
): string => {
  if ((originalRow as Exemption).id) {
    return (originalRow as Exemption).id;
  }

  if ((originalRow as RuleRow).metric) {
    return (originalRow as RuleRow).metric;
  }

  return parent ? [parent.id, relativeIndex].join('.') : `${relativeIndex}`;
};

/**
 * Converts an AggregationRecommendation to an AggregationRule
 * @param recommendation can be undefined as _.pick is null safe
 */
export const recommendationToRule = (recommendation?: AggregationRecommendation): AggregationRule => {
  const propsToPick: Array<keyof AggregationRule> = ['metric', 'match_type'];

  if (hasRuleProp(recommendation, 'drop')) {
    propsToPick.push('drop');
  } else {
    propsToPick.push('aggregations');
    if (hasRuleProp(recommendation, 'drop_labels')) {
      propsToPick.push('drop_labels');
    } else {
      propsToPick.push('keep_labels');
    }
  }
  const res = _pick(recommendation, propsToPick) as AggregationRule;
  return { ...res, match_type: res.match_type || 'exact' };
};

/**
 * Convert an AggregationRule or AggregationRecommendation to a RuleRow
 * @param rule
 * @param recommendedAction optional recommended action to add to the RuleRow
 */
export const convertToRuleRow = (
  rule: AggregationRule | AggregationRecommendation,
  recommendedAction?: Recommendation
): RuleRow => {
  const { match_type, metric, ...rest } = rule;
  return {
    match_type: match_type || 'exact',
    metric,
    ruleType: rule.drop ? 'Drop metric' : 'Aggregation',
    ...(recommendedAction ? { recommended_action: recommendedAction } : {}),
    summary: { ...rest },
  };
};

export const ruleRowToRuleJson = (ruleRow: RuleRow): AggregationRule => {
  const {
    match_type,
    metric,
    ruleType,
    summary: { aggregations, drop_labels, keep_labels },
  } = ruleRow;
  const base = { ...(match_type !== 'exact' ? { match_type } : {}), metric };
  if (ruleType === 'Drop metric') {
    return { ...base, drop: true };
  }

  return { ...base, aggregations, ...(drop_labels ? { drop_labels } : { keep_labels }) };
};

export const ruleRowToRuleJsonString = (ruleRow: RuleRow) => {
  return JSON.stringify(ruleRowToRuleJson(ruleRow), null, 2);
};

export const ruleRowToRecommendationJson = (ruleRow: RuleRow): AggregationRecommendation => {
  const {
    recommended_action,
    summary: {
      total_series_after_aggregation,
      total_series_before_aggregation,
      usages_in_dashboards,
      usages_in_queries,
      usages_in_rules,
    },
  } = ruleRow;
  const base = ruleRowToRuleJson(ruleRow);

  return {
    ...base,
    recommended_action: recommended_action || 'unknown',
    total_series_after_aggregation,
    total_series_before_aggregation,
    usages_in_dashboards,
    usages_in_queries,
    usages_in_rules,
  };
};

/**
 * Compare two AggregationRules. If deepCompare is true, then the entire object is compared. Otherwise,
 * only the metric and match_type are compared.
 * @param a
 * @param b
 * @param deepCompare
 */
export const compareRule = (a: AggregationRule, b: AggregationRule, deepCompare = false) => {
  if (a.metric !== b.metric) {
    return false;
  }

  const massagedA = { ...a, match_type: a.match_type || 'exact' };
  const massagedB = { ...b, match_type: b.match_type || 'exact' };

  if (deepCompare) {
    return _isEqual(massagedA, massagedB);
  }

  // we need to include match_type because a prefix or suffix could match a metric name
  return massagedA.match_type === massagedB.match_type;
};

export const ruleDiff = (a: AggregationRule, b: AggregationRule) => {
  const response: Array<keyof AggregationRule> = [];

  const massagedA = { ...a, match_type: a.match_type || 'exact' };
  const massagedB = { ...b, match_type: b.match_type || 'exact' };

  if (
    'aggregations' in massagedA &&
    'aggregations' in massagedB &&
    !_isEqual(massagedA.aggregations, massagedB.aggregations)
  ) {
    response.push('aggregations');
  }

  if ('drop' in massagedA && 'drop' in massagedB && !_isEqual(massagedA.drop, massagedB.drop)) {
    response.push('drop');
  }

  if (
    'drop_labels' in massagedA &&
    'drop_labels' in massagedB &&
    !_isEqual(massagedA.drop_labels, massagedB.drop_labels)
  ) {
    response.push('drop_labels');
  }

  if (
    'keep_labels' in massagedA &&
    'keep_labels' in massagedB &&
    !_isEqual(massagedA.keep_labels, massagedB.keep_labels)
  ) {
    response.push('keep_labels');
  }

  if ('match_type' in massagedA && 'match_type' in massagedB && !_isEqual(massagedA.match_type, massagedB.match_type)) {
    response.push('match_type');
  }

  return response;
};

/**
 * Add an AggregationRule to the list of AggregationRules if it does not exist. If it does exist, replace it.
 * @param rules
 * @param rule
 * @param ruleKey optional
 */
export const addOrUpdateRule = (
  rules: AggregationRule[],
  rule: AggregationRule,
  ruleKey?: Symbol
): AggregationRule[] => {
  const index = rules.findIndex((r) => (ruleKey ? getRuleKey(r) === ruleKey : compareRule(r, rule)));

  if (index === -1) {
    return [...rules, rule];
  }

  return [...rules.slice(0, index), rule, ...rules.slice(index + 1)];
};

/**
 * Remove an AggregationRule from the list of AggregationRules if it exists. If it does not exist show an error alert
 * and return the original list of AggregationRules.
 * @param rules
 * @param rule
 * @param suppressError
 */
export const removeRule = (
  rules: AggregationRule[],
  rule: AggregationRule,
  suppressError = false
): AggregationRule[] => {
  const index = rules.findIndex((r) => compareRule(r, rule));
  if (index === -1) {
    if (!suppressError) {
      errorAlert('Could not find rule to remove');
    }
    return rules;
  }
  return [...rules.slice(0, index), ...rules.slice(index + 1)];
};

/**
 * Removes all the rules passed in from the original list of AggregationRule if they exist.
 * @param list
 * @param toRemove
 */
export const removeRules = (list: AggregationRule[], toRemove: AggregationRule[] = []) => {
  let updatedList = list;
  let failed = false;
  toRemove.forEach((ruleToRemove: AggregationRule) => {
    updatedList = removeRule(updatedList, ruleToRemove, true);
    if (updatedList.findIndex((r) => compareRule(r, ruleToRemove)) > -1) {
      failed = true;
    }
  });

  if (failed) {
    errorAlert('Some rules could not be found to remove');
  }

  return updatedList;
};

export const getRuleKey = (rule: AggregationRule): Symbol => {
  return Symbol.for(JSON.stringify({ match_type: rule.match_type || 'exact', metric: rule.metric }));
};

export const getExemptionKey = (id: string): Symbol => {
  return Symbol.for(id);
};

export const getLookBackTimeRange = (hourDiff = 1): TimeRange => {
  const to = new Date();
  const from = subHours(to, hourDiff);

  return {
    from: dateTime(from),
    raw: {
      from: formatISO(from),
      to: formatISO(to),
    },
    to: dateTime(to),
  };
};

export const isApplied = (
  rule: AggregationRule | undefined,
  recommendation: AggregationRule | undefined,
  isRemoveRec: boolean
) => {
  return (
    Boolean(rule && recommendation && compareRule(rule, recommendation, true) && !isRemoveRec) ||
    Boolean(!rule && recommendation && isRemoveRec)
  );
};

export const pageDefinitionToRoute = (page: PageDefinition) => _kebabCase(page.id);
export const pageTypeFromRoute = (pageRoute: string): PageType => _camelCase(pageRoute) as PageType;

export const noop = () => {
  return;
};

export const loadMetricNameOptions = async (query: string): Promise<SelectableValue[]> => {
  const trimmedQuery = _trim(query);
  const lookback = getLookBackTimeRange();

  const params = {
    ...(trimmedQuery ? { 'match[]': `{__name__=~"(?i:(.*${trimmedQuery}.*))"}` } : {}),
    end: lookback.to.unix(),
    limit: 1000,
    start: lookback.from.unix(),
  };

  const data = await getWithHeaders<{ data: string[]; warnings?: string[] }>(paths.grafanaPromMetrics, params);

  return data.items[0].data.map((each) => ({ label: each, value: each }));
};

import React, { useEffect, useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { toLower as _toLower, trim as _trim } from 'lodash';

import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { reportInteraction } from '@grafana/runtime';
import {
  AsyncMultiSelect,
  AsyncSelect,
  Button,
  Checkbox,
  ConfirmModal,
  Field,
  InlineSwitch,
  Input,
  Spinner,
  useStyles2,
  useTheme2,
} from '@grafana/ui';

import { Exemption } from '@/api/types';
import {
  useCreateExemptionsMutation,
  useCurrentPage,
  useExemptions,
  usePromLabels,
  useUpdateExemptionsMutation,
  useUserPermissions,
} from '@/hooks';
import { errorAlert } from '@/util/alert';
import { loadMetricNameOptions, noop } from '@/util/methods';

const getStyles = (theme: GrafanaTheme2) => ({
  inlineFields: css({
    display: 'flex',
    gap: theme.spacing(1),
  }),
});

const exemptionToSelectableValues = (
  exemption?: Exemption
): {
  all_labels: boolean;
  all_metrics: boolean;
  keep_labels?: Array<SelectableValue<string>>;
  metric?: SelectableValue<string>;
} => {
  if (exemption) {
    const result: {
      all_labels: boolean;
      all_metrics: boolean;
      keep_labels?: Array<SelectableValue<string>>;
      metric?: SelectableValue<string>;
    } = {
      all_labels: false,
      all_metrics: false,
    };

    if (exemption.metric) {
      result.metric = { label: exemption.metric, value: exemption.metric };
    } else {
      result.metric = { label: 'All metrics', value: undefined };
      result.all_metrics = true;
    }

    if (exemption.keep_labels) {
      const keeps: Array<SelectableValue<string>> = [];
      exemption.keep_labels.forEach((label) => {
        keeps.push({ label, value: label });
      });
      result.keep_labels = keeps;
      // future proofing against keep_labels coming back as an empty array
      result.all_labels = !exemption.disable_recommendations && !keeps.length;
    } else {
      result.all_labels = !exemption.disable_recommendations;
    }

    return result;
  } else {
    // default to false when creating
    return { all_labels: false, all_metrics: false };
  }
};

type InvalidFieldOpts = 'all-labels' | 'all-metrics' | 'disable-recommendations' | 'labels' | 'metric';

type Props = {
  disabled?: boolean;
  exemption?: Exemption;
};

export const AddEditExemption = ({ disabled, exemption }: Props) => {
  const theme = useTheme2();
  const styles = useStyles2(getStyles);
  const userPermissions = useUserPermissions();
  const page = useCurrentPage();

  const [isOpen, setIsOpen] = useState(false);

  const { isFetching: isFetchingExemption } = useExemptions();
  const { isLoading: isExemptionCreating, mutateAsync: createExemptionAsync } = useCreateExemptionsMutation();
  const { isLoading: isExemptionUpdating, mutateAsync: updateExemptionAsync } = useUpdateExemptionsMutation();

  const [invalidFields, setInvalidFields] = useState<Set<InvalidFieldOpts>>(new Set());

  const exemptionSelectableValues = exemptionToSelectableValues(exemption);
  const { data: labelsData, isLoading: labelsLoading } = usePromLabels();

  const [selectedMetric, setSelectedMetric] = useState<SelectableValue<string> | undefined>();
  const [selectedLabels, setSelectedLabels] = useState<Array<SelectableValue<string>> | undefined>();

  const [selectAllMetrics, setSelectAllMetrics] = useState(false);
  const [selectAllLabels, setSelectAllLabels] = useState(false);

  const [exemptionReason, setExemptionReason] = useState<string | undefined>();
  const [disableRecommendations, setDisableRecommendations] = useState<boolean>(false);

  const busy = isExemptionCreating || isExemptionUpdating;

  useEffect(() => {
    setInvalidFields(new Set());
    setSelectedMetric(exemptionSelectableValues.metric);
    setSelectedLabels(exemptionSelectableValues.keep_labels);
    setSelectAllMetrics(exemptionSelectableValues.all_metrics);
    setSelectAllLabels(exemptionSelectableValues.all_labels);
    setExemptionReason(exemption?.reason);
    setDisableRecommendations(!!exemption?.disable_recommendations);
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadLabelOptions = useMemo(() => {
    if (labelsData?.items && labelsData?.items.length > 0) {
      return (term: string): Promise<Array<SelectableValue<string>>> => {
        const queryTerm = _toLower(_trim(term));
        return Promise.resolve(
          labelsData.items[0].data
            .filter((label) => _toLower(label).includes(queryTerm))
            .map((label) => ({ label, value: label }))
        );
      };
    }
    return (_: string): Promise<Array<SelectableValue<string>>> => Promise.resolve([]);
  }, [labelsData]);

  const formHasErrors = () => {
    invalidFields.clear();
    let hasErrors = false;
    const labels: string[] = selectedLabels?.filter((label) => Boolean(label.value)).map((label) => label.value!) || [];

    const isAllMetricsAndLabelsSelected = selectAllMetrics && selectAllLabels;
    const isAllMetricsSelectedAndDisableRecommendationOn = selectAllMetrics && disableRecommendations;
    const isLabelSelectedAndDisabledRecommendationOn =
      selectedLabels && selectedLabels.length && disableRecommendations;
    const isMetricEmpty = !selectedMetric?.value && !selectAllMetrics;
    const isLabelEmpty = !labels.length && !selectAllLabels;
    const areBothEmpty = isMetricEmpty && isLabelEmpty;

    if (isAllMetricsSelectedAndDisableRecommendationOn) {
      errorAlert('All metrics cannot be set when disabling recommendations');
      hasErrors = true;
      invalidFields.add('all-metrics');
      invalidFields.add('disable-recommendations');
    } else if (isLabelSelectedAndDisabledRecommendationOn) {
      errorAlert('Labels cannot be defined when disabling recommendations');
      hasErrors = true;
      invalidFields.add('labels');
      invalidFields.add('disable-recommendations');
    } else if (isAllMetricsAndLabelsSelected) {
      errorAlert('Both metric and labels cannot be set to all');
      hasErrors = true;
      invalidFields.add('all-labels');
      invalidFields.add('all-metrics');
    } else if (areBothEmpty) {
      errorAlert('Both metric and labels cannot be empty');
      hasErrors = true;
      invalidFields.add('labels');
      invalidFields.add('metric');
    } else {
      if (isMetricEmpty) {
        errorAlert('Metric cannot be empty');
        hasErrors = true;
        invalidFields.add('metric');
      }
      if (isLabelEmpty && !disableRecommendations) {
        errorAlert('Labels cannot be empty');
        hasErrors = true;
        invalidFields.add('labels');
      }
    }

    if (hasErrors) {
      setInvalidFields(new Set(invalidFields));
    } else {
      setInvalidFields(new Set());
    }
    return hasErrors;
  };

  const onConfirm = async () => {
    const payload: Partial<Exemption> = { id: exemption?.id };

    if (!formHasErrors()) {
      payload.metric = selectedMetric?.value;
      payload.keep_labels = selectedLabels?.map((label) => label.value || '').filter((label) => label) || undefined;
      payload.reason = exemptionReason;
      payload.disable_recommendations = disableRecommendations;

      reportInteraction('g_adaptive_metrics_app_exemption', {
        action: exemption ? 'update' : 'create',
        disableRecommendations: disableRecommendations,
        isAllLabel: selectAllLabels,
        isAllMetric: selectAllMetrics,
        labelCount: selectedLabels?.length,
        metric: Boolean(payload.metric),
        page,
      });
      if (payload.id) {
        await updateExemptionAsync(payload);
      } else {
        await createExemptionAsync(payload);
      }
      setIsOpen(false);
    }
  };

  if (labelsLoading || isFetchingExemption) {
    return (
      <Button
        disabled
        icon={exemption ? 'pen' : undefined}
        size={exemption ? 'sm' : undefined}
        variant={'secondary'}
        onClick={noop}
      >
        {exemption ? '' : 'Add new'}
        <Spinner className={css({ marginLeft: theme.spacing(1) })} />
      </Button>
    );
  }

  return (
    <>
      <Button
        aria-label={exemption ? 'Edit exemption' : 'Add new exemption'}
        disabled={!userPermissions.canWriteExemptions || disabled}
        icon={exemption ? 'pen' : undefined}
        size={exemption ? 'sm' : undefined}
        variant={exemption ? 'secondary' : 'primary'}
        tooltip={
          !userPermissions.canWriteExemptions
            ? `You don't have permission for this action`
            : exemption
              ? 'Edit exemption'
              : 'Add new exemption'
        }
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {exemption ? '' : 'Add new'}
      </Button>
      <ConfirmModal
        isOpen={isOpen}
        title={exemption ? 'Edit exemption' : 'Add exemption'}
        body={
          <>
            <Field label={'Metric'}>
              <span className={styles.inlineFields}>
                <AsyncSelect
                  key={`metric-${JSON.stringify(selectedMetric)}`}
                  inputId="metric-dropdown"
                  aria-label="metric-dropdown"
                  isClearable
                  isSearchable
                  defaultOptions
                  disabled={selectAllMetrics}
                  invalid={invalidFields.has('metric')}
                  loadOptions={loadMetricNameOptions}
                  value={selectedMetric}
                  onChange={(value) => {
                    setSelectedMetric(value);
                  }}
                  allowCustomValue
                />
                <InlineSwitch
                  label="All"
                  aria-label="All metrics"
                  showLabel
                  transparent
                  value={selectAllMetrics}
                  invalid={invalidFields.has('all-metrics')}
                  onChange={({ currentTarget }) => {
                    setSelectAllMetrics(currentTarget.checked);

                    if (currentTarget.checked) {
                      setSelectedMetric({ label: 'All metrics', value: undefined });
                      setDisableRecommendations(false);
                    } else {
                      setSelectedMetric(undefined);
                    }
                  }}
                />
              </span>
            </Field>
            <Field>
              <Checkbox
                aria-label="disable-recommendations"
                disabled={selectAllMetrics}
                invalid={invalidFields.has('disable-recommendations')}
                label="Disable recommendations for this metric and preserve existing rule state"
                value={disableRecommendations}
                onChange={(value) => {
                  setDisableRecommendations(value.currentTarget.checked);

                  if (value.currentTarget.checked) {
                    setSelectedLabels([]);
                    setSelectAllLabels(false);
                  }
                }}
              />
            </Field>
            <Field label={'Keep labels'}>
              <span className={styles.inlineFields}>
                <AsyncMultiSelect
                  key={`labels-${JSON.stringify(selectedLabels)}`}
                  inputId="label-dropdown"
                  aria-label="label-dropdown"
                  defaultOptions
                  disabled={disableRecommendations || selectAllLabels}
                  invalid={invalidFields.has('labels')}
                  isSearchable
                  loadOptions={loadLabelOptions}
                  value={selectedLabels}
                  placeholder={selectAllLabels ? 'All labels' : 'Choose'}
                  onChange={(value) => {
                    setSelectedLabels(value);
                  }}
                  allowCustomValue
                />
                <InlineSwitch
                  disabled={disableRecommendations}
                  label="All"
                  aria-label="All labels"
                  invalid={invalidFields.has('all-labels')}
                  showLabel
                  transparent
                  value={selectAllLabels}
                  onChange={({ currentTarget }) => {
                    setSelectAllLabels(currentTarget.checked);
                    setSelectedLabels([]);
                  }}
                />
              </span>
            </Field>
            <Field label="Reason">
              <Input
                value={exemptionReason}
                onChange={(value) => setExemptionReason(value.currentTarget.value)}
                placeholder="Optional"
              />
            </Field>
          </>
        }
        confirmText={'Save'}
        confirmButtonVariant={busy ? 'secondary' : 'primary'}
        onConfirm={busy ? noop : onConfirm}
        onDismiss={
          busy
            ? noop
            : () => {
                setIsOpen(false);
              }
        }
      />
    </>
  );
};

import React, { useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { cloneDeep as _cloneDeep } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, ConfirmModal, Icon, useStyles2 } from '@grafana/ui';

import { AggregationRule } from '@/api/types';
import { ActionData } from '@/components/ConfirmationModal/types';
import { reportBatch } from '@/components/PageHeader/util';
import {
  useCheckRulesMutation,
  useCurrentPage,
  useRules,
  useSelectedItems,
  useUpdateRulesMutation,
  useUserPermissions,
} from '@/hooks';
import { errorAlert } from '@/util/alert';
import { externalLinks } from '@/util/constants';
import { noop } from '@/util/methods';

const getStyles = (theme: GrafanaTheme2) => ({
  boldSubtext: css({
    color: theme.colors.emphasize(theme.colors.text.primary, 0.075),
  }),
  modalBody: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  }),
  subtext: css({
    alignItems: 'baseline',
    color: theme.colors.text.secondary,
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  }),
});

export const BatchRemoveRules = () => {
  const styles = useStyles2(getStyles);
  const page = useCurrentPage();
  const userPermissions = useUserPermissions();
  const [isBatchRemoveOpen, setIsBatchRemoveOpen] = useState(false);
  const { isLoading: isLoadingCheckRules, mutateAsync: checkRulesAsync } = useCheckRulesMutation();
  const { isLoading: isLoadingUpdateRules, mutateAsync: updateRulesAsync } = useUpdateRulesMutation();
  const { clearSelection, selectedItems } = useSelectedItems(page);
  const { data: currentRulesData } = useRules();

  const currentAppliedInSelection = useMemo(() => {
    let res = new Set<Symbol>();

    selectedItems.forEach((key) => {
      if (currentRulesData?.mappedItems.has(key)) {
        res.add(key);
      }
    });

    return res;
  }, [currentRulesData?.mappedItems, selectedItems]);

  const [updatedRules, batchInteractionData] = useMemo(() => {
    let res = _cloneDeep(currentRulesData?.mappedItems) || new Map<Symbol, AggregationRule>();
    if (currentAppliedInSelection.size) {
      currentAppliedInSelection.forEach((key) => {
        if (res.has(key)) {
          res.delete(key);
        }
      });
    }
    return [
      Array.from(res.values()),
      {
        add: { ruleKeys: new Set<Symbol>() } as ActionData,
        keep: { ruleKeys: new Set<Symbol>() } as ActionData,
        remove: { ruleKeys: new Set<Symbol>([...currentAppliedInSelection]) } as ActionData,
        unknown: { ruleKeys: new Set<Symbol>() } as ActionData,
        update: { ruleKeys: new Set<Symbol>() } as ActionData,
      },
    ];
  }, [currentAppliedInSelection, currentRulesData?.mappedItems]);

  const batchRemoveIsBusy = isLoadingCheckRules || isLoadingUpdateRules;

  const onRemoveConfirm = async () => {
    if (!currentRulesData) {
      errorAlert('Something went wrong. Refresh the page and try again.');
      return;
    }

    reportBatch('g_adaptive_metrics_app_batch_apply', batchInteractionData, 'ruleManagement');

    await checkRulesAsync(updatedRules);
    await updateRulesAsync({ eTag: currentRulesData.eTag, rules: updatedRules });
    await clearSelection();
    setIsBatchRemoveOpen(false);
  };

  if (!userPermissions.canDeleteRules) {
    return null;
  }

  return (
    <>
      <Button
        aria-label={'Batch remove rules'}
        variant={'destructive'}
        tooltip={'Bulk remove applied rules'}
        onClick={() => setIsBatchRemoveOpen(true)}
        disabled={!currentAppliedInSelection.size}
      >
        <Icon name={'trash-alt'} />
      </Button>
      <ConfirmModal
        isOpen={isBatchRemoveOpen}
        title={'Remove selected rules'}
        body={
          <div className={styles.modalBody}>
            <div>Are you sure you want to remove all of the selected rules?</div>
            <div>
              {currentAppliedInSelection.size} applied rule{currentAppliedInSelection.size > 1 ? 's' : ''} will be
              removed.
            </div>
            <div className={styles.subtext}>
              <Icon name={'info-circle'} />
              <span>
                This action only removes currently applied rules and has no affect on new{' '}
                <span className={styles.boldSubtext}>add</span> recommendations or future recommendations for removed
                rules. If you want to stop recommendations for specific metrics, use the exemptions feature in the
                Configuration (
                <a className={'external-link'} target="_blank" rel="noreferrer" href={externalLinks.exemptionsDoc}>
                  <span style={{ whiteSpace: 'nowrap' }}>
                    read more <Icon size="sm" name="external-link-alt" />
                  </span>
                </a>
                ).
              </span>
            </div>
          </div>
        }
        confirmButtonVariant={batchRemoveIsBusy ? 'secondary' : 'destructive'}
        confirmText={'Remove selected rules'}
        onConfirm={batchRemoveIsBusy ? noop : onRemoveConfirm}
        onDismiss={
          batchRemoveIsBusy
            ? noop
            : () => {
                setIsBatchRemoveOpen(false);
              }
        }
        disabled={!currentAppliedInSelection.size}
      />
    </>
  );
};

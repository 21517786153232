import React, { useState } from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, ConfirmModal, useStyles2 } from '@grafana/ui';

import { Exemption } from '@/api/types';
import { AddEditExemption } from '@/components/Exemptions/AddEditExemption';
import { QUERY_KEYS, useDeleteExemptionMutation, useExemptions } from '@/hooks';
import { noop } from '@/util/methods';
import { queryClient } from '@/util/state';

interface Props {
  exemption: Exemption;
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    actionColumn: css({
      display: 'flex',
      gap: theme.spacing(1),
    }),
  };
};

export const ExemptionActionButtons = ({ exemption }: Props) => {
  const styles = useStyles2(getStyles);
  const [isOpen, setIsOpen] = useState(false);
  const { isLoading: isExemptionDeleting, mutateAsync: deleteExemptionAsync } = useDeleteExemptionMutation();
  const { isFetching: isFetchingExemption } = useExemptions();

  const isDeleting = isExemptionDeleting || isFetchingExemption;

  return (
    <div className={styles.actionColumn}>
      <AddEditExemption exemption={exemption} />

      <Button
        aria-label={'Delete Exemption'}
        data-testid="delete-icon-button"
        icon="trash-alt"
        size={'sm'}
        variant={'secondary'}
        onClick={() => {
          setIsOpen(true);
        }}
      />
      <ConfirmModal
        isOpen={isOpen}
        title="Delete Exemption?"
        body={<div>Exemption will be removed</div>}
        confirmText={'Delete'}
        confirmButtonVariant={isDeleting ? 'secondary' : 'destructive'}
        onConfirm={
          isDeleting
            ? noop
            : async () => {
                await deleteExemptionAsync(exemption.id);
                await queryClient.invalidateQueries([QUERY_KEYS.exemptions]);
                await setIsOpen(false);
              }
        }
        onDismiss={
          isDeleting
            ? noop
            : () => {
                setIsOpen(false);
              }
        }
      />
    </div>
  );
};

import { has as _has } from 'lodash';

// These types have changed a few times and may need to be updated.
export type Recommendation = 'add' | 'keep' | 'remove' | 'unknown' | 'update';
export type Aggregation = 'count' | 'max' | 'min' | 'sum' | 'sum:counter';
export type RecommendedActionFilter = Recommendation | 'all';

// Make sure aggregationTypeValues contains all the values of Aggregation
export const aggregationTypeValues: Aggregation[] = ['count', 'max', 'min', 'sum', 'sum:counter'];

export type MatchType = 'exact' | 'prefix' | 'suffix';
/**
 * drop, drop_labels, and keep_labels are mutually exclusive.
 */
export type AggregationRule = {
  aggregations?: Aggregation[];
  drop?: boolean;
  drop_labels?: string[];
  keep_labels?: string[];
  managed_by?: string;
  match_type?: MatchType;
  metric: string;
};

export type AggregationRecommendation = AggregationRule & {
  // These will appear if verbose mode is active See https://grafana.com/docs/grafana-cloud/data-configuration/metrics/manage-metrics-costs-via-adaptive-metrics/
  recommended_action: Recommendation;
  total_series_after_aggregation?: number;
  total_series_before_aggregation?: number;
  usages_in_dashboards?: number;
  usages_in_queries?: number;
  usages_in_rules?: number;
};

export type ExemptionsQueryResponse = {
  result: Exemption[];
};

export type Exemption = {
  created_at: string;
  disable_recommendations?: boolean;
  id: string;
  keep_labels?: string[];
  managed_by?: string;
  metric?: string;
  reason?: string;
  updated_at: string;
};

export type ListResponseWithHeaders<T> = {
  eTag: string;
  items: T[];
  lastModified: string;
  mappedItems: Map<Symbol, T>;
  rulesVersion: string;
};

export type RecommendationsConfigResponse = {
  keep_labels: string[];
};

export const hasRuleProp = (obj: unknown, prop: keyof AggregationRule) => {
  return _has(obj, prop);
};

export const isAggregationRuleLike = (obj: unknown) => {
  return (
    hasRuleProp(obj, 'metric') &&
    ((hasRuleProp(obj, 'drop') &&
      !hasRuleProp(obj, 'drop_labels') &&
      !hasRuleProp(obj, 'keep_labels') &&
      !hasRuleProp(obj, 'aggregations')) ||
      ((hasRuleProp(obj, 'drop_labels') || hasRuleProp(obj, 'keep_labels')) && hasRuleProp(obj, 'aggregations')))
  );
};

export type QueryResult = {
  metric: {
    __name__: string;
    [key: string]: string;
  };
  values: Array<[number, string]>; // [timestamp, value]
};

export type QueryResponse = {
  data: {
    result: QueryResult[];
    resultType: string;
  };
  status: string;
};

export type QueryRangeResponse = QueryResponse;

// Partial representation of an instance object from gcom
export type GcomInstance = {
  clusterId: number;
  clusterSlug: string;
  hmInstancePromId: number;
  hmInstancePromUrl: string;
  id: number;
  orgId: number;
  orgSlug: string;
  plan: string;
  planName: string;
  provider: string;
  regionId: number;
  regionSlug: string;
  slug: string;
  version: string;
  [p: string]: unknown;
};

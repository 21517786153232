import React, { useEffect, useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { toLower as _toLower, trim as _trim } from 'lodash';

import { formatLabels, parseLabels, SelectableValue } from '@grafana/data';
import { AsyncSelect, Button, Checkbox, ConfirmModal, Field, Input, Select, Spinner, useTheme2 } from '@grafana/ui';

import { useLabelValues, usePromLabels, useSegments, useSegmentsAddMutation } from '@/hooks';
import { errorAlert } from '@/util/alert';
import { noop } from '@/util/methods';

export const AddSegment = () => {
  const theme = useTheme2();
  const [isOpen, setIsOpen] = useState(false);
  const { data: segmentsData, isLoading: segmentsLoading } = useSegments();
  const { isLoading: segmentAddIsLoading, mutateAsync: segmentAddMutateAsync } = useSegmentsAddMutation();
  const { data: labelsData, isLoading: labelsLoading } = usePromLabels();

  const [invalidFields, setInvalidFields] = useState<Set<string>>(new Set());
  const [selectedLabel, setSelectedLabel] = useState<SelectableValue<string> | undefined>(undefined);
  const [selectedValue, setSelectedValue] = useState<SelectableValue<string> | undefined>(undefined);
  const [segmentName, setSegmentName] = useState('');
  const [fallbackToDefault, setFallbackToDefault] = useState(false);
  const { data: labelValues } = useLabelValues(selectedLabel?.value);

  const existingLabel = useMemo(() => {
    if (segmentsData?.items && segmentsData?.items.length > 0) {
      return Object.keys(parseLabels(segmentsData.items[0].selector))[0];
    }
    return '';
  }, [segmentsData]);

  const existingValues = useMemo(() => {
    if (segmentsData?.items && segmentsData?.items.length > 0) {
      const values = segmentsData.items.map((item) => {
        return Object.values(parseLabels(item.selector))[0];
      });
      return new Set<string>(values);
    }
    return new Set<string>();
  }, [segmentsData]);

  useEffect(() => {
    setInvalidFields(new Set());
    if (existingLabel) {
      setSelectedLabel({ label: existingLabel, value: existingLabel });
    } else {
      setSelectedLabel(undefined);
    }
    setSelectedValue(undefined);
    setSegmentName('');
    setFallbackToDefault(false);
  }, [isOpen, existingLabel]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadLabelOptions = useMemo(() => {
    if (labelsData?.items && labelsData?.items.length > 0) {
      return (term: string): Promise<Array<SelectableValue<string>>> => {
        const queryTerm = _toLower(_trim(term));
        return Promise.resolve(
          labelsData.items[0].data
            .filter((label) => _toLower(label).includes(queryTerm))
            .map((label) => ({ label, value: label }))
        );
      };
    }
    return (_: string): Promise<Array<SelectableValue<string>>> => Promise.resolve([]);
  }, [labelsData]);

  const valueOptions = useMemo(() => {
    if (labelValues?.length) {
      const allValues = new Set([...labelValues, ...existingValues]);
      return Array.from(allValues).map(
        (value): SelectableValue<string> => ({
          isDisabled: existingValues.has(value),
          label: value,
          title: existingValues.has(value) ? `Segment already created for ${value}` : '',
          value,
        })
      );
    }
    return [];
  }, [existingValues, labelValues]);

  if (segmentsLoading || labelsLoading) {
    return (
      <Button onClick={noop} disabled>
        Add new <Spinner className={css({ marginLeft: theme.spacing(1) })} />
      </Button>
    );
  }

  const formHasErrors = () => {
    invalidFields.clear();
    let hasErrors = false;

    if (!selectedLabel?.value && !existingLabel) {
      errorAlert('Label cannot be empty');
      hasErrors = true;
      invalidFields.add('label');
    }

    if (!selectedValue?.value) {
      errorAlert('Value cannot be empty');
      hasErrors = true;
      invalidFields.add('value');
    }

    if (!segmentName) {
      errorAlert('Name cannot be empty');
      hasErrors = true;
      invalidFields.add('name');
    }

    if (hasErrors) {
      setInvalidFields(new Set(invalidFields));
    } else {
      setInvalidFields(new Set());
    }
    return hasErrors;
  };

  const onConfirm = async () => {
    if (!formHasErrors()) {
      await segmentAddMutateAsync({
        segment: {
          fallback_to_default: fallbackToDefault,
          name: segmentName,
          selector: formatLabels({ [selectedLabel!.value!]: selectedValue!.value! }), // asserting the values because formHasErrors checks them
        },
      });
      setIsOpen(false);
    }
  };

  const busy = segmentAddIsLoading;

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Add new</Button>
      <ConfirmModal
        isOpen={isOpen}
        title={'Add segment'}
        body={
          <>
            <Field label={'Name'} htmlFor={'segment-name'} required>
              <Input
                id={'segment-name'}
                invalid={invalidFields.has('name')}
                value={segmentName}
                onChange={(event) => setSegmentName(event.currentTarget.value)}
              />
            </Field>
            <Field label={'Label'} htmlFor={'segment-label'} required>
              <AsyncSelect
                inputId={'segment-label'}
                onChange={(value) => {
                  setSelectedLabel(value);
                  setSelectedValue(undefined);
                }}
                invalid={invalidFields.has('label')}
                loadOptions={loadLabelOptions}
                defaultOptions
                disabled={Boolean(existingLabel)}
                value={selectedLabel}
                isClearable
                allowCustomValue
              />
            </Field>
            <Field label={'Value'} htmlFor={'segment-value'} required>
              <Select
                inputId={'segment-value'}
                key={`value-${selectedLabel?.value || 'undefined'}`}
                onChange={(value) => {
                  setSelectedValue(value);
                }}
                invalid={invalidFields.has('value')}
                options={valueOptions}
                value={selectedValue}
                disabled={!selectedLabel}
                isClearable
                isSearchable
                allowCustomValue
              />
            </Field>
            <Field htmlFor={'fallback-to-default'}>
              <Checkbox
                id={'fallback-to-default'}
                aria-label={'Fallback to default rules'}
                checked={fallbackToDefault}
                onChange={(event) => setFallbackToDefault(event.currentTarget.checked)}
                label={'Fallback to default rules'}
              />
            </Field>
          </>
        }
        confirmButtonVariant={busy ? 'secondary' : 'primary'}
        confirmText={'Add'}
        onConfirm={busy ? noop : onConfirm}
        onDismiss={
          busy
            ? noop
            : () => {
                setIsOpen(false);
              }
        }
      />
    </>
  );
};

import React, { useMemo } from 'react';

import { Modal } from '@grafana/ui';

import { useRecommendations, useRules } from '@/hooks';

type ModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
};
export const LastUpdatedModal = ({ isOpen, onDismiss }: ModalProps) => {
  const { data: currentRules } = useRules();
  const { data: recommendations } = useRecommendations(false);

  const lastUpdated = useMemo(() => {
    if (!currentRules && !recommendations) {
      return null;
    }
    return { currenRules: currentRules?.lastModified, recommendations: recommendations?.lastModified };
  }, [currentRules, recommendations]);

  return (
    <Modal
      isOpen={isOpen}
      title={'Last updated information'}
      onDismiss={onDismiss}
      closeOnBackdropClick={true}
      closeOnEscape={true}
    >
      <dl>
        <dt>Applied Rules</dt>
        <dd>{lastUpdated?.currenRules}</dd>
        <dt>Recommendations</dt>
        <dd>{lastUpdated?.recommendations}</dd>
      </dl>
    </Modal>
  );
};

import React, { useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { useFlag } from '@openfeature/react-sdk';
import { useQueryClient } from '@tanstack/react-query';
import { some as lodashSome } from 'lodash';
import Skeleton from 'react-loading-skeleton';

import { GrafanaTheme2 } from '@grafana/data';
import { config, reportInteraction } from '@grafana/runtime';
import { Button, Column, ConfirmModal, useStyles2, useTheme2 } from '@grafana/ui';

import { Exemption } from '@/api/types';
import { ConfirmModalLoader } from '@/components/ConfirmModalLoader';
import { ContentBox } from '@/components/ContentBox';
import { AddEditExemption } from '@/components/Exemptions/AddEditExemption';
import { ExemptionActionButtons } from '@/components/Exemptions/ExemptionActionButtons';
import { ExemptionSummary } from '@/components/Exemptions/ExemptionSummary';
import { QueryResultHeader } from '@/components/QueryResultHeader';
import { SegmentSelector } from '@/components/SegmentSelector';
import { TableWithCheckbox } from '@/components/TableWithCheckbox';
import {
  QUERY_KEYS,
  useCurrentPage,
  useDeleteExemptionMutation,
  useDownloadExemption,
  useExemptions,
  useSegments,
  useUserPermissions,
} from '@/hooks';
import { warningAlert } from '@/util/alert';
import { noop } from '@/util/methods';

const getStyles = (theme: GrafanaTheme2) => {
  const bg = config.theme2.colors.background;
  return {
    allMetric: css({
      color: theme.colors.text.secondary,
      fontStyle: 'italic',
    }),
    applyButtons: css({
      display: 'flex',
      gap: theme.spacing(1),
    }),
    contentContainer: css({
      background: bg.primary,
      display: 'flex',
      flexDirection: 'column',
      padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    }),
    controls: css({
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(2),
    }),
    grow: css({
      flexGrow: 1,
    }),
    metricColumn: css({
      width: theme.spacing(30),
    }),
    metricEllipsis: css({
      display: 'inline-block',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
  };
};

type Styles = ReturnType<typeof getStyles>;

const getColumns = (styles: Styles, canUserEditExemption: boolean): Array<Column<Exemption>> => {
  return [
    {
      cell: ({ row: { original } }) => (
        <div className={styles.metricColumn}>
          {original.metric ? (
            <span className={styles.metricEllipsis}>{original.metric}</span>
          ) : (
            <span className={styles.allMetric}>All metrics</span>
          )}
        </div>
      ),
      disableGrow: true,
      header: 'Metric',
      id: 'metric',
    },
    {
      header: 'Reason',
      id: 'reason',
    },
    {
      cell: ({ row }) => {
        return canUserEditExemption && <ExemptionActionButtons exemption={row.original} />;
      },
      disableGrow: true,
      header: 'Actions',
      id: 'actions',
    },
  ];
};

const ExemptionsBody = ({ children }: { children: React.ReactNode }) => {
  const styles = useStyles2(getStyles);
  return (
    <ContentBox>
      <h3>Exemptions</h3>
      <div className={styles.contentContainer}>{children}</div>
    </ContentBox>
  );
};

export const Exemptions = () => {
  const styles = useStyles2(getStyles);
  const theme = useTheme2();
  const page = useCurrentPage();
  const { value: segmentSelectorEnabled } = useFlag('segment_selector', false);

  const {
    data: exemptionsData,
    error: exemptionsError,
    isError: exemptionsIsError,
    isFetching: isFetchingExemption,
  } = useExemptions();
  const userPermissions = useUserPermissions();
  const queryClient = useQueryClient();

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const { isLoading: isExemptionDeleting, mutateAsync: deleteExemptionAsync } = useDeleteExemptionMutation(true);

  const { isLoading: isSegmentsLoading } = useSegments();

  const downloadExemptions = useDownloadExemption();

  const columns = useMemo(
    () => getColumns(styles, userPermissions.canWriteExemptions),
    [styles, userPermissions.canWriteExemptions]
  );

  const tableData = useMemo(() => {
    if (!exemptionsData || exemptionsData?.length === 0) {
      return [];
    }

    return exemptionsData;
  }, [exemptionsData]);

  const renderSubComponent = (exemption: Exemption) => {
    return <ExemptionSummary exemption={exemption} />;
  };

  const onExemptionRemove = async () => {
    const exemptionsToDelete = exemptionsData?.map(({ id }) => id) || [];

    const results = await Promise.allSettled(exemptionsToDelete.map((uid) => deleteExemptionAsync(uid)));
    const anyFailed = lodashSome(results, (result) => result.status === 'rejected');

    if (anyFailed) {
      warningAlert('Some exemptions failed to delete. Try again or contact support.', 'Deletion Failed');
    }

    reportInteraction('g_adaptive_metrics_app_exemption', { action: 'remove', page, removeCount: results.length });
    await queryClient.invalidateQueries([QUERY_KEYS.exemptions]);
  };

  const isDeleting = isExemptionDeleting || isFetchingExemption;
  const isLoading = isSegmentsLoading || isFetchingExemption;

  if (isLoading) {
    return (
      <ExemptionsBody>
        <div className={styles.controls}>
          {segmentSelectorEnabled && (
            <>
              <SegmentSelector />
              <span className={styles.grow} />
            </>
          )}
          <span className={styles.applyButtons}>
            {userPermissions.canWriteExemptions && (
              <>
                <AddEditExemption />
                <Button aria-label={'Remove all exemptions'} disabled={true} variant={'destructive'} onClick={noop}>
                  Remove all
                </Button>
              </>
            )}
            <Button
              aria-label={'Download exemptions'}
              variant="secondary"
              icon="file-download"
              onClick={downloadExemptions}
              disabled={true}
            />
          </span>
        </div>
        <Skeleton count={5} height={theme.spacing(4)} />
      </ExemptionsBody>
    );
  }

  if (exemptionsIsError) {
    return (
      <ExemptionsBody>
        <QueryResultHeader isErrorArr={[exemptionsIsError]} isLoadingArr={[]} errors={[exemptionsError]} />
      </ExemptionsBody>
    );
  }

  return (
    <ContentBox>
      <h3>Exemptions</h3>
      <div className={styles.contentContainer}>
        <div className={styles.controls}>
          {segmentSelectorEnabled && (
            <>
              <SegmentSelector />
              <span className={styles.grow} />
            </>
          )}
          <span className={styles.applyButtons}>
            {userPermissions.canWriteExemptions && (
              <>
                <AddEditExemption />
                <Button
                  aria-label={'Remove all exemptions'}
                  disabled={!exemptionsData?.length}
                  variant={'destructive'}
                  onClick={() => setDeleteModalIsOpen(true)}
                >
                  Remove all
                </Button>
                <ConfirmModal
                  isOpen={deleteModalIsOpen}
                  title="Delete Exemptions?"
                  body={
                    <>
                      {exemptionsData?.length} exemption{exemptionsData?.length === 1 ? '' : 's'} will be removed
                    </>
                  }
                  confirmText={
                    isDeleting ? ((<ConfirmModalLoader text="Deleting..." />) as unknown as string) : 'Delete'
                  }
                  confirmButtonVariant="destructive"
                  onConfirm={async () => {
                    await onExemptionRemove();
                    setDeleteModalIsOpen(false);
                  }}
                  onDismiss={() => {
                    setDeleteModalIsOpen(false);
                  }}
                />
              </>
            )}
            <Button
              aria-label={'Download exemptions'}
              variant="secondary"
              icon="file-download"
              onClick={downloadExemptions}
            />
          </span>
        </div>
        <TableWithCheckbox columns={columns} data={tableData} subComponent={renderSubComponent} pageSize={5} />
      </div>
    </ContentBox>
  );
};

import React from 'react';

import { AppRootProps } from '@grafana/data';
import { config } from '@grafana/runtime';
import { Alert } from '@grafana/ui';

import PageWrapper from '@/components/PageWrapper';
import { AdaptiveMetricsContextProvider } from '@/context/adaptive-metrics-context';
import { AppPluginSettings } from '@/types';
import { MINIMUM_GRAFANA_VERSION } from '@/util/grafana';

export const UnsupportedVersion = (props: AppRootProps<AppPluginSettings>) => {
  return (
    <AdaptiveMetricsContextProvider instance={props.meta.jsonData?.instance}>
      <PageWrapper logo={props.meta.info.logos.large}>
        <Alert title="Upgrade required" severity="error">
          <p>
            Your current Grafana version (<code>{config.buildInfo.version}</code>) is not compatible with the Adaptive
            Metrics plugin, which requires Grafana version <code>{MINIMUM_GRAFANA_VERSION}</code> or higher.
          </p>
          <div>Contact Customer Support to upgrade.</div>
        </Alert>
        <Alert title={'Note'} severity={'info'}>
          Your existing rules are still in effect. This only affects the use of the plugin.
        </Alert>
      </PageWrapper>
    </AdaptiveMetricsContextProvider>
  );
};

import { AppPlugin } from '@grafana/data';
import { config } from '@grafana/runtime';

import { App } from '@/pages/App';
import { NoAccess } from '@/pages/NoAccess';
import { UnsupportedVersion } from '@/pages/UnsupportedVersion';
import { AppPluginSettings } from '@/types';
import { getUserPermissions, isGrafanaVersionSupported } from '@/util/grafana';

export const plugin = new AppPlugin<AppPluginSettings>();

if (process.env.NODE_ENV === 'development') {
  if (process.env.ENABLE_MSW === 'true') {
    const { worker } = require('./_mocks_/browser');
    worker.start({
      onUnhandledRequest: 'bypass',
      serviceWorker: {
        options: {
          scope: '/a/',
        },
        url: '/public/msw/mockServiceWorker.js',
      },
    });
  }
  if (process.env.APP_URL) {
    config.appUrl = process.env.APP_URL;
  }
}

const userPermissions = getUserPermissions();

if (userPermissions.canAccessPlugin) {
  if (isGrafanaVersionSupported()) {
    plugin.setRootPage(App);
  } else {
    plugin.setRootPage(UnsupportedVersion);
  }
} else {
  plugin.setRootPage(NoAccess);
}

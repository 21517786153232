import React from 'react';

import { Field } from '@grafana/ui';

import { LabelDisplay } from '@/components/LabelDisplay';
import { SeriesReduction } from '@/components/RuleSummary/SeriesReduction';
import { RuleRow } from '@/types';

type Props = {
  ruleRow: RuleRow;
};

export const RuleDisplay = ({ ruleRow }: Props) => {
  return (
    <div>
      <h2>Recommended rule information</h2>
      <hr />
      {ruleRow.ruleType === 'Drop metric' ? (
        <div>This rule drops the entire metric. No time series will be persisted for the metric.</div>
      ) : (
        <>
          {ruleRow.summary.total_series_before_aggregation && ruleRow.summary.total_series_after_aggregation && (
            <SeriesReduction ruleRow={ruleRow} />
          )}
          {ruleRow.summary.managed_by && (
            <Field label="Managed by">
              <>{ruleRow.summary.managed_by}</>
            </Field>
          )}
          {ruleRow.summary.aggregations && (
            <>
              <LabelDisplay
                labels={ruleRow.summary.aggregations}
                title={'Aggregations'}
                existingLabels={ruleRow.summary.existingRule?.aggregations}
              />
            </>
          )}
          {ruleRow.summary.drop_labels && (
            <LabelDisplay
              labels={ruleRow.summary.drop_labels}
              title={'Drop labels'}
              existingLabels={ruleRow.summary.existingRule?.drop_labels}
            />
          )}
          {
            /* Verbose recommendations include keep_labels by default.
             * Make sure we don't have drop_labels before showing keep_labels */
            !ruleRow.summary.drop_labels && ruleRow.summary.keep_labels && (
              <LabelDisplay
                labels={ruleRow.summary.keep_labels}
                title={'Keep labels'}
                existingLabels={!ruleRow.summary.drop_labels && ruleRow.summary.existingRule?.keep_labels}
              />
            )
          }
        </>
      )}
    </div>
  );
};

import { useContext, useMemo } from 'react';

import { some as _some, values as _values } from 'lodash';

import { AdaptiveMetricsContext } from '@/context/adaptive-metrics-context';
import { PageType, RuleRow } from '@/types';
import { getUserPermissions } from '@/util/grafana';

export const useSelectedItems = (page: PageType) => {
  const { selectedItems, setRuleSelection, setSelectedItems, toggleSelectedItems } = useContext(AdaptiveMetricsContext);

  if (!selectedItems) {
    throw new Error('useSelectedItems must be used within AdaptiveMetricsContextProvider');
  }

  return {
    clearSelection: () => {
      setSelectedItems({ ...selectedItems, [page]: new Set<Symbol>() });
    },
    selectedItems: selectedItems[page],
    selectedRulesAsArray: Array.from(selectedItems[page]),
    setRuleSelection: (ruleKey: Symbol, isSelected: boolean) => {
      setRuleSelection(page, ruleKey, isSelected);
    },
    setSelectedItems: (ruleKeys: Set<Symbol>) => {
      setSelectedItems({ ...selectedItems, [page]: ruleKeys });
    },
    toggleSelectedItems: (ruleKey: Symbol) => {
      toggleSelectedItems(page, ruleKey);
    },
  };
};

export const usePageFilters = (page: PageType) => {
  const { pageFilters, setPageFilters } = useContext(AdaptiveMetricsContext);

  const isFiltered = useMemo(() => {
    if (pageFilters) {
      return _some(_values(pageFilters[page]));
    }
    return false;
  }, [page, pageFilters]);

  if (!pageFilters) {
    throw new Error('usePageFilters must be used within AdaptiveMetricsContextProvider');
  }

  const toggleShowOnlySelected = (page: PageType) => {
    setPageFilters({
      ...pageFilters,
      [page]: { ...pageFilters[page], showOnlySelected: !pageFilters[page].showOnlySelected },
    });
  };

  const toggleShowOnlyWithRecommendations = (page: PageType) => {
    setPageFilters({
      ...pageFilters,
      [page]: { ...pageFilters[page], showOnlyWithRecommendations: !pageFilters[page].showOnlyWithRecommendations },
    });
  };

  return {
    isFiltered,
    metricFilter: pageFilters[page].metricFilter,
    setMetricFilter: (val: string) => {
      setPageFilters({
        ...pageFilters,
        [page]: { ...pageFilters[page], metricFilter: val },
      });
    },
    showOnlySelected: pageFilters[page].showOnlySelected,
    showOnlyWithRecommendations: pageFilters[page].showOnlyWithRecommendations,
    toggleShowOnlySelected: () => {
      toggleShowOnlySelected(page);
    },
    toggleShowOnlyWithRecommendations: () => {
      toggleShowOnlyWithRecommendations(page);
    },
  };
};

export const useTableData = (page: PageType) => {
  const { setTableData, tableData } = useContext(AdaptiveMetricsContext);

  if (!tableData) {
    throw new Error('useTableData must be used within AdaptiveMetricsContextProvider');
  }

  return {
    setTableData: (data: RuleRow[]) => {
      setTableData({ ...tableData, [page]: data });
    },
    tableData: tableData[page],
  };
};

export const useLookBackTimeRange = () => {
  const { lookBackTimeRange } = useContext(AdaptiveMetricsContext);

  if (!lookBackTimeRange) {
    throw new Error('useLookBackTimeRange must be used within AdaptiveMetricsContextProvider');
  }

  return lookBackTimeRange;
};

/**
 * returns a query modifier that filters results to an id if instance was defined.
 */
export const useIdQueryModifier = () => {
  const { instance } = useContext(AdaptiveMetricsContext);

  if (!instance) {
    throw new Error(
      'useIdQueryModifier must be used within AdaptiveMetricsContextProvider, or instance is not defined in plugin jsonData'
    );
  }

  return `{id="${instance}"}`;
};

export const useUserPermissions = () => getUserPermissions();

export const useExemptionExpand = () => {
  const { exemptionExpandSet, toggleExemptionExpand } = useContext(AdaptiveMetricsContext);

  if (exemptionExpandSet === undefined) {
    throw new Error('useExemptionExpand must be used within AdaptiveMetricsContextProvider');
  }

  return {
    exemptionExpandSet,
    toggleExemptionExpand,
  };
};

export const useRecommendedActionFilter = () => {
  const { recommendedActionFilter, setRecommendedActionFilter } = useContext(AdaptiveMetricsContext);

  if (!recommendedActionFilter) {
    throw new Error('useRecommendedActionFilter must be used within AdaptiveMetricsContextProvider');
  }

  return {
    recommendedActionFilter,
    setRecommendedActionFilter,
  };
};

export const useSegmentContext = () => {
  const { segmentId, setSegmentId, updateSegmentParams } = useContext(AdaptiveMetricsContext);

  if (!segmentId) {
    throw new Error('useSegment must be used within AdaptiveMetricsContextProvider');
  }

  return {
    segmentId,
    setSegmentId,
    updateSegmentParams,
  };
};

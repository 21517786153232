import React, { useMemo } from 'react';

import { css } from '@emotion/css';
import { sortBy as _sortBy } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { reportInteraction } from '@grafana/runtime';
import { Alert, Column, Icon, Tooltip, useStyles2 } from '@grafana/ui';

import { AggregationRecommendation, AggregationRule } from '@/api/types';
import { ItemCheckbox } from '@/components/ItemCheckbox';
import { MetricCell } from '@/components/MetricCell';
import { RuleManagementPageHeader } from '@/components/PageHeader/RuleManagement';
import { QueryResultHeader } from '@/components/QueryResultHeader';
import { RuleActions } from '@/components/RuleActions';
import { EditRule } from '@/components/RuleActions/EditRule';
import { RuleSummary } from '@/components/RuleSummary';
import { SeriesImpactCell } from '@/components/SeriesImpactCell';
import { TableHeaderCheckbox } from '@/components/TableHeaderCheckbox';
import { TableWithCheckbox } from '@/components/TableWithCheckbox';
import { useCurrentPage, useRecommendations, useRules } from '@/hooks';
import { RuleRow } from '@/types';
import { convertToRuleRow, getRuleKey, isApplied, recommendationToRule } from '@/util/methods';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    icon: css({
      marginLeft: theme.spacing(0.5),
      verticalAlign: 'text-top',
    }),
    keepsFilter: css({
      width: theme.spacing(30),
    }),
    noRecAddButtonContainer: css({
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    }),
    noRecsContainer: css({
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      padding: theme.spacing(1),
    }),
    pagination: css({
      alignSelf: 'flex-end',
      float: 'none',
      marginTop: theme.spacing(1),
    }),
    tableContainer: css({
      display: 'flex',
      flexDirection: 'column',
    }),
    urgentTagStyle: css({
      span: {
        backgroundColor: theme.colors.warning.transparent,
        border: `1px solid ${theme.colors.warning.border}`,
        color: theme.colors.getContrastText(theme.colors.warning.transparent),
      },
    }),
  };
};

type Styles = ReturnType<typeof getStyles>;

const getColumns = (styles: Styles): Array<Column<RuleRow>> => {
  return [
    {
      cell: ({ row }) => {
        const ruleRow: RuleRow = row.original;
        return <ItemCheckbox item={ruleRow} />;
      },
      disableGrow: true,
      header: (<TableHeaderCheckbox />) as unknown as string,
      id: 'rule-selector',
    } as Column<RuleRow>,
    {
      cell: ({ row }) => {
        const ruleRow: RuleRow = row.original;
        return <MetricCell row={ruleRow} />;
      },
      header: 'Metric',
      id: 'metric',
    } as Column<RuleRow>,
    {
      cell: ({ row }) => {
        const ruleRow: RuleRow = row.original;
        return <SeriesImpactCell ruleRow={ruleRow} />;
      },
      header: (
        <>
          {'Series impact'}
          <Tooltip
            content={'If known, the impact on time series applying this rule will have for the affected metric.'}
            placement={'top'}
          >
            <Icon className={styles.icon} name={'info-circle'} />
          </Tooltip>
        </>
      ) as unknown as string,
      id: 'series-impact',
    } as Column<RuleRow>,
    {
      cell: ({ row }) => {
        const ruleRow: RuleRow = row.original;
        return <RuleActions ruleRow={ruleRow} />;
      },
      disableGrow: true,
      header: 'Actions',
      id: 'actions',
    } as Column<RuleRow>,
  ];
};

export const RuleManagement = () => {
  const styles = useStyles2(getStyles);
  const page = useCurrentPage();

  const {
    data: currentRulesData,
    error: currentRulesError,
    isError: currentRulesIsError,
    isLoading: currentRulesIsLoading,
  } = useRules();

  const {
    data: recommendationsData,
    error: recommendationsError,
    isError: recommendationsIsError,
    isLoading: recommendationsIsLoading,
  } = useRecommendations(true);

  const {
    data: recommendationsNonVerboseData,
    error: recommendationsNonVerboseError,
    isError: recommendationsNonVerboseIsError,
    isLoading: recommendationsNonVerboseIsLoading,
  } = useRecommendations(false);

  const columns = useMemo(() => getColumns(styles), [styles]);

  const mergedRuleData: Array<AggregationRecommendation & { existingRule?: AggregationRule }> = useMemo(() => {
    if (recommendationsIsLoading || currentRulesIsLoading) {
      return [];
    }

    const res: Array<AggregationRecommendation & { existingRule?: AggregationRule }> = [];

    recommendationsData?.mappedItems.forEach((recommendation) => {
      let toPush: AggregationRecommendation & { existingRule?: AggregationRule } = recommendation;

      const existingRule: AggregationRule | undefined = currentRulesData?.mappedItems.get(getRuleKey(recommendation));

      if (existingRule) {
        toPush = { ...toPush, existingRule };

        // Update to keep since the rule has already been applied
        // Remove recommendations won't update to keep if there is an existing rule
        if (
          isApplied(
            existingRule,
            recommendationToRule(recommendation),
            Boolean(recommendation.recommended_action === 'remove')
          )
        ) {
          toPush.recommended_action = 'keep';
        }
      } else {
        if (recommendation.recommended_action !== 'remove') {
          toPush.recommended_action = 'add';
        }
      }
      // Push to array if 'add', 'update', 'keep', or 'remove' where there is an existing rule
      if (toPush.recommended_action !== 'remove' || toPush.existingRule) {
        res.push(toPush);
      }
    });

    currentRulesData?.mappedItems.forEach((currentRule) => {
      // If there is an applied rule that doesn't have a recommendation we want to push to the array as a 'keep'
      if (!Boolean(recommendationsData?.mappedItems.get(getRuleKey(currentRule)))) {
        res.push({ ...currentRule, existingRule: currentRule, recommended_action: 'keep' });
      }
    });

    return res;
  }, [currentRulesData, recommendationsData, recommendationsIsLoading, currentRulesIsLoading]);

  const updatesTableData = useMemo(() => {
    if (mergedRuleData.length === 0) {
      return [];
    }

    return _sortBy(
      mergedRuleData.map((rule): RuleRow => {
        return convertToRuleRow(rule, rule.recommended_action || 'unknown');
      }),
      (value) => -(value.summary.usages_in_rules || 0)
    );
  }, [mergedRuleData]);

  if (
    currentRulesIsLoading ||
    recommendationsIsLoading ||
    recommendationsNonVerboseIsLoading ||
    !currentRulesData ||
    !recommendationsData ||
    !recommendationsNonVerboseData
  ) {
    return (
      <QueryResultHeader
        errors={[recommendationsError, recommendationsNonVerboseError, currentRulesError]}
        isErrorArr={[recommendationsIsError, recommendationsNonVerboseIsError, currentRulesIsError]}
        isLoadingArr={[recommendationsIsLoading, recommendationsNonVerboseIsLoading, currentRulesIsLoading]}
      />
    );
  }

  const renderSubComponent = (ruleRow: RuleRow) => {
    reportInteraction('g_adaptive_metrics_app_metric_rule_expanded', { metric: ruleRow.metric, page });
    return <RuleSummary ruleRow={ruleRow} />;
  };

  if (mergedRuleData.length === 0 && !recommendationsData.lastModified) {
    return (
      <div className={styles.noRecsContainer}>
        <Alert title="Why am I not seeing any recommendations?" severity="warning">
          <div>Recommendations haven’t been generated yet. Check back within 24 hours.</div>
        </Alert>
        <div className={styles.noRecAddButtonContainer}>
          Want to get started? Create your own rule.
          <EditRule />
        </div>
      </div>
    );
  }

  return (
    <div>
      <QueryResultHeader
        errors={[recommendationsError, currentRulesError]}
        isErrorArr={[recommendationsIsError, currentRulesIsError]}
        isLoadingArr={[recommendationsIsLoading, currentRulesIsLoading]}
      />
      <RuleManagementPageHeader />
      <div className={styles.tableContainer}>
        <TableWithCheckbox columns={columns} data={updatesTableData} subComponent={renderSubComponent} />
      </div>
    </div>
  );
};

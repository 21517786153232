import React from 'react';

import { AppRootProps } from '@grafana/data';

import { AccessDenied } from '@/components/AccessDenied';
import PageWrapper from '@/components/PageWrapper';
import { AdaptiveMetricsContextProvider } from '@/context/adaptive-metrics-context';
import { AppPluginSettings } from '@/types';

export const NoAccess = (props: AppRootProps<AppPluginSettings>) => {
  return (
    <AdaptiveMetricsContextProvider instance={props.meta.jsonData?.instance}>
      <PageWrapper logo={props.meta.info.logos.large}>
        <AccessDenied />
      </PageWrapper>
    </AdaptiveMetricsContextProvider>
  );
};

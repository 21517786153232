import { Configuration } from '@/pages/Configuration';
import { Overview } from '@/pages/Overview';
import { RuleManagement } from '@/pages/RuleManagement';
import { PageDefinition, PageType } from '@/types';

export const pages = new Map<PageType, PageDefinition>([
  [
    'configuration',
    {
      component: Configuration,
      id: 'configuration',
      reqAction: 'grafana-adaptive-metrics-app.exemptions:read',
      text: 'Configuration',
    },
  ],
  [
    'overview',
    {
      component: Overview,
      id: 'overview',
      reqAction: 'grafana-adaptive-metrics-app.plugin:access',
      text: 'Overview',
    },
  ],
  [
    'ruleManagement',
    {
      component: RuleManagement,
      id: 'ruleManagement',
      reqAction: 'grafana-adaptive-metrics-app.recommendations:read',
      text: 'Rules',
    },
  ],
]);

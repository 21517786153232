import React, { useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { useFlag } from '@openfeature/react-sdk';
import { some as _some, values as _values } from 'lodash';

import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { reportInteraction } from '@grafana/runtime';
import { Button, ConfirmModal, Divider, InlineSwitch, RadioButtonGroup, useStyles2 } from '@grafana/ui';

import { AggregationRule } from '@/api/types';
import { ConfirmationModal } from '@/components/ConfirmationModal';
import { ActionData, ConfirmationData } from '@/components/ConfirmationModal/types';
import { ContentBox } from '@/components/ContentBox';
import { BatchRemoveRules } from '@/components/PageHeader/BatchRemoveRules';
import { FilterField } from '@/components/PageHeader/FilterField';
import { LastUpdatedModal } from '@/components/PageHeader/LastUpdatedInfo';
import { reportBatch } from '@/components/PageHeader/util';
import { EditRule } from '@/components/RuleActions/EditRule';
import { SegmentSelector } from '@/components/SegmentSelector';
import {
  useCurrentPage,
  usePageFilters,
  useRecommendations,
  useRules,
  useSelectedItems,
  useUserPermissions,
} from '@/hooks';
import type { PageType } from '@/types';
import { warningAlert } from '@/util/alert';
import { DIVISIONS, LARGE_SPAN } from '@/util/constants';
import { downloadJson } from '@/util/download';
import { isApplied, recommendationToRule } from '@/util/methods';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    alignContentRight: css({
      justifyContent: 'flex-end',
    }),
    applyButtons: css({
      alignItems: 'flex-end',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    }),
    base: css({
      display: 'grid',
      gridTemplateColumns: '3fr 2fr',
      marginTop: theme.spacing(1),
    }),
    buttons: css({
      alignItems: 'flex-end',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      justifyContent: 'space-between',
    }),
    downloadModalBody: css({
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    }),
    filters: css({
      display: 'grid',
      gap: theme.spacing(1),
      gridTemplateColumns: `repeat(${DIVISIONS}, 1fr)`,
    }),
    flexRowContent: css({
      display: 'flex',
      gap: theme.spacing(1),
    }),
    flexWrap: css({
      flexWrap: 'wrap',
    }),
    fullSpan: css({
      gridColumn: `span ${DIVISIONS}`,
    }),
    largeSpan: css({
      gridColumn: `span ${LARGE_SPAN}`,
    }),
    selectedText: css({
      color: theme.colors.text.secondary,
      display: 'inline-block',
      fontStyle: 'italic',
      marginRight: theme.spacing(1),
    }),
  };
};

const downloadTooltips: { [key in PageType]?: string } = {
  ruleManagement: 'Download applied rules or recommendations',
};

const downloadRulesRecommendationsOptions: Array<SelectableValue<'recommendations' | 'rules'>> = [
  { label: 'Recommendations', value: 'recommendations' },
  { label: 'Applied rules', value: 'rules' },
];

const downloadAllSelectedOptions: Array<SelectableValue<'all' | 'selected'>> = [
  { label: 'All', value: 'all' },
  { label: 'Selected', value: 'selected' },
];

const downloadVerboseNonVerboseOptions: Array<SelectableValue<'nonVerbose' | 'verbose'>> = [
  { label: 'Verbose', value: 'verbose' },
  { label: 'Non-verbose', value: 'nonVerbose' },
];

export function RuleManagementPageHeader() {
  const [batchApplyModalIsOpen, setBatchApplyModalIsOpen] = useState(false);

  const [downloadModalIsOpen, setDownloadModalIsOpen] = useState(false);
  const [downloadRulesRecommendations, setDownloadRulesRecommendations] = useState<'recommendations' | 'rules'>(
    'recommendations'
  );
  const [downloadAllSelected, setDownloadAllSelected] = useState<'all' | 'selected'>('all');
  const [downloadVerboseNonVerbose, setDownloadVerboseNonVerbose] = useState<'nonVerbose' | 'verbose'>('verbose');

  const [lastUpdatedModalOpen, setLastUpdatedModalOpen] = useState(false);

  const page = useCurrentPage();
  const userPermissions = useUserPermissions();
  const styles = useStyles2(getStyles);
  const { data: currentRules } = useRules();
  const { data: recommendationsVerbose } = useRecommendations(true);
  const { data: recommendationsNonVerbose } = useRecommendations(false);
  const { clearSelection, selectedItems } = useSelectedItems(page);
  const { showOnlySelected, toggleShowOnlySelected } = usePageFilters(page);
  const { value: batchRemoveRulesEnabled } = useFlag('batch_remove_rules', false);
  const { value: segmentSelectorEnabled } = useFlag('segment_selector', false);

  const batchActionData = useMemo(() => {
    const data: ConfirmationData = {
      add: { ruleKeys: new Set<Symbol>() } as ActionData,
      keep: { ruleKeys: new Set<Symbol>() } as ActionData,
      remove: { ruleKeys: new Set<Symbol>() } as ActionData,
      unknown: { ruleKeys: new Set<Symbol>() } as ActionData,
      update: { ruleKeys: new Set<Symbol>() } as ActionData,
    };
    if (currentRules && recommendationsVerbose) {
      (selectedItems.size ? selectedItems : Array.from(recommendationsVerbose.mappedItems.keys())).forEach((symbol) => {
        const rec = recommendationsVerbose?.mappedItems.get(symbol);

        if (rec) {
          const curr = currentRules?.mappedItems.get(symbol);
          const recApplied = isApplied(curr, recommendationToRule(rec), rec?.recommended_action === 'remove');

          if (!recApplied) {
            const dataAction = data[rec.recommended_action];
            dataAction.ruleKeys.add(symbol);

            if (rec.total_series_before_aggregation && rec.total_series_after_aggregation) {
              dataAction.seriesBefore = (dataAction.seriesBefore || 0) + rec.total_series_before_aggregation;
              dataAction.seriesAfter = (dataAction.seriesAfter || 0) + rec.total_series_after_aggregation;
            }
          }
        }
      });
    }
    return data;
  }, [currentRules, recommendationsVerbose, selectedItems]);

  const batchActionDisabled = useMemo(() => {
    if (batchActionData) {
      return !_some(_values(batchActionData), (actionData: ActionData) => actionData.ruleKeys.size > 0);
    }
    return true;
  }, [batchActionData]);

  const onDownload = useMemo(() => {
    let data: Map<Symbol, AggregationRule> | undefined;
    if (downloadRulesRecommendations === 'rules') {
      data = currentRules?.mappedItems;
    } else {
      data =
        downloadVerboseNonVerbose === 'verbose'
          ? recommendationsVerbose?.mappedItems
          : recommendationsNonVerbose?.mappedItems;
    }

    if (data) {
      let downloadData = [
        ...(downloadAllSelected === 'all'
          ? data
          : new Map([...data].filter(([key, _]) => selectedItems.has(key)))
        ).values(),
      ];
      return () => {
        if (downloadData.length) {
          const isVerboseRecommendations =
            downloadRulesRecommendations === 'recommendations' && downloadVerboseNonVerbose === 'verbose';
          downloadJson(
            downloadData,
            `${downloadRulesRecommendations}${isVerboseRecommendations ? '-verbose' : ''}-${downloadAllSelected}-${Date.now()}.json`
          );
          reportInteraction('g_adaptive_metrics_app_download_metric_rules_json', {
            isDownloadAll: downloadAllSelected === 'all',
            quantitySelected: selectedItems.size,
            rulesOrRecommendations: downloadRulesRecommendations,
          });
          resetDownloadModal();
        } else {
          if (downloadAllSelected === 'selected') {
            warningAlert(
              `Selected rows are only available as ${downloadRulesRecommendations === 'recommendations' ? 'applied rules' : 'recommendations'}. Choose 'All' or change your selection.`
            );
          }
        }
      };
    }

    return () => {
      warningAlert('No data available to download. Refresh the page and try again.');
      resetDownloadModal();
    };
  }, [
    downloadRulesRecommendations,
    downloadAllSelected,
    downloadVerboseNonVerbose,
    recommendationsNonVerbose,
    recommendationsVerbose,
    currentRules,
    selectedItems,
  ]);

  const resetDownloadModal = () => {
    setDownloadAllSelected('all');
    setDownloadRulesRecommendations('recommendations');
    setDownloadVerboseNonVerbose('verbose');
    setDownloadModalIsOpen(false);
  };

  const isApplyAll = !selectedItems.size;

  const primaryButtonText = !isApplyAll
    ? `Apply selected recommendation${selectedItems.size > 1 ? 's' : ''}`
    : 'Apply all recommendations';

  return (
    <ContentBox className={styles.base}>
      <div className={styles.filters}>
        {segmentSelectorEnabled && <SegmentSelector />}
        <FilterField className={styles.largeSpan} />
        <div className={cx(styles.fullSpan, styles.flexRowContent, styles.flexWrap)}>
          <InlineSwitch
            label={`Show selected`}
            showLabel={true}
            transparent={true}
            value={showOnlySelected}
            onChange={() => toggleShowOnlySelected()}
          />
        </div>
        <div className={cx(styles.fullSpan, styles.flexRowContent)}>
          <div>
            <span className={styles.selectedText}>{`${selectedItems.size} selected`}</span>
            {selectedItems.size > 0 && (
              <Button
                data-testid={'clear-selection-button'}
                variant={'primary'}
                fill={'text'}
                onClick={clearSelection}
                icon={'times-circle'}
                tooltip={'Clear selection'}
              />
            )}
          </div>
        </div>
      </div>
      <div className={cx(styles.flexRowContent, styles.alignContentRight)}>
        <div className={styles.buttons}>
          <div className={styles.flexRowContent}>
            <Button
              data-testid={'pageheader-info'}
              variant={'secondary'}
              icon={'info-circle'}
              tooltip={'View information about applied rules and recommendations'}
              onClick={() => setLastUpdatedModalOpen(true)}
            />
            <LastUpdatedModal
              isOpen={lastUpdatedModalOpen}
              onDismiss={() => {
                setLastUpdatedModalOpen(false);
              }}
            />
            <Button
              data-testid={'pageheader-download'}
              variant={'secondary'}
              icon={'file-download'}
              tooltip={downloadTooltips[page] || ''}
              onClick={() => setDownloadModalIsOpen(true)}
            />
            <ConfirmModal
              isOpen={downloadModalIsOpen}
              title={'Download'}
              body={
                <div className={styles.downloadModalBody}>
                  <RadioButtonGroup
                    options={downloadAllSelectedOptions}
                    fullWidth
                    disabledOptions={!selectedItems.size ? ['selected'] : undefined}
                    onChange={(value: 'all' | 'selected') => setDownloadAllSelected(value)}
                    value={downloadAllSelected}
                  />
                  <Divider />
                  <RadioButtonGroup
                    options={downloadRulesRecommendationsOptions}
                    fullWidth
                    onChange={(value: 'recommendations' | 'rules') => setDownloadRulesRecommendations(value)}
                    value={downloadRulesRecommendations}
                  />
                  {downloadRulesRecommendations === 'recommendations' && (
                    <RadioButtonGroup
                      fullWidth
                      options={downloadVerboseNonVerboseOptions}
                      value={downloadVerboseNonVerbose}
                      onChange={(value: 'nonVerbose' | 'verbose') => setDownloadVerboseNonVerbose(value)}
                    />
                  )}
                </div>
              }
              confirmText="Download"
              onConfirm={onDownload}
              confirmButtonVariant="primary"
              onDismiss={() => {
                resetDownloadModal();
              }}
            />
          </div>
          <div className={styles.flexRowContent}>
            {userPermissions.canApplyRecommendations && (
              <span className={styles.applyButtons}>
                <Button
                  disabled={batchActionDisabled}
                  variant={'primary'}
                  onClick={() => setBatchApplyModalIsOpen(!batchApplyModalIsOpen)}
                >
                  {primaryButtonText}
                </Button>
                <ConfirmationModal
                  confirmText={'Apply'}
                  data={batchActionData}
                  isOpen={batchApplyModalIsOpen}
                  onConfirm={() => {
                    reportBatch('g_adaptive_metrics_app_batch_apply', batchActionData, page, isApplyAll, false);
                    setBatchApplyModalIsOpen(false);
                  }}
                  onDismiss={() => {
                    setBatchApplyModalIsOpen(false);
                  }}
                  title={'Change rules?'}
                />
              </span>
            )}
            {batchRemoveRulesEnabled && <BatchRemoveRules />}
            <EditRule />
          </div>
        </div>
      </div>
    </ContentBox>
  );
}
